import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import {
  FilterOrder,
  changeStatus,
  getOrders,
  userListing,
  userListingDownload,
} from "../Redux/Actions/adminAction";
import Search from "../Components/Layout/Search";
import ShowingResult from "../Components/Layout/ShowingResult";
import Limit from "../Components/Layout/Limit";
import { toast } from "react-toastify";
import Pagination from "../Components/Layout/Pagination";
import * as XLSX from "xlsx";

export default function OrderManagement() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setpage] = useState("1");
  const [search, setsearch] = useState("");
  const [limit, setlimit] = useState("10");
  const [flag, setflag] = useState(true);
  const [Orderlist, setOrderList] = useState([]);
  const [pagi, setPagi] = useState("");
  const [type, setType] = useState("");

  useEffect(() => {
    dispatch(getOrders({ page, search, limit,type }))
      .then((res) => {
        console.log(res);
        if (res?.payload?.status_code === 200) {
          setOrderList(res?.payload?.data?.bookings);
          setPagi(res?.payload?.data);
        }
      })
      .catch((err) => {
        console.log(err, "Error Occur");
      });
  }, [page, search, limit, flag,type]);



  const startIndex = 1 + (page - 1) * limit;
  const endIndex = Math.min(page * limit, pagi?.totalBookings);

  const handleProfile = (id) => {
    navigate(`/OrderDetails/${id}`);
  };

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>Order Management</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={4} md={4}>
                <Form.Group>
                  <Form.Control
                    type="search"
                    placeholder="Keyword Search.."
                    name="search"
                    onChange={(e) => setsearch(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col xxl={2} xl={3} lg={4} md={4}>
                <Form.Select
                  aria-label="Default select example"
                  onChange={(e) => setType(e.target.value)}
                >
                  <option>Order status</option>
                  <option value="">All</option>
                  <option value="1">Pending</option>
                  <option value="2">Accepted</option>
                  <option value="3">Canceled</option>
                  <option value="4">Compeleted</option>
                </Form.Select>
              </Col>
              <Col
                xxl={7}
                xl={5}
                lg={4}
                md={4}
                className="d-flex justify-content-end align-items-center"
              >
                {/* <div className="cmn-btn">
                  <Link onClick={fetchDataAndExportToExcel}>DOWNLOAD</Link>
                </div> */}
              </Col>
            </Row>
          </div>
        </Container>
        <hr />
        <Container fluid>
          <div className="product-tab">
            <ul>
              <li>
                <Link to="/CustomerManagement" className="active-tab">
                  All
                </Link>
              </li>
              {/* <li>
                <Link to="">Completed </Link>
              </li>
              <li>
                <Link to="">Rejected</Link>
              </li>
              <li>
                <Link to="">Pending</Link>
              </li> */}
            </ul>
          </div>
        </Container>
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">
                {" "}
                Showing {startIndex} - {endIndex} of {pagi?.totalBookings}{" "}
                results
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span> <Limit setlimit={setlimit} />
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Customer</th>
                <th>Service Provider</th>
                <th>Service Category</th>
                <th>Date</th>
                <th>Service Status</th>
              </tr>
            </thead>
            <tbody>
              {Orderlist?.map((res, index) => {
                const serialNumber = (page - 1) * limit + index + 1;
                return (
                  <tr>
                    <td>{serialNumber}</td>

                    <td>
                      <p style={{ cursor: "pointer" }}
                        onClick={() => handleProfile(res?._id)}>
                        <b> {res?.bookBy?.full_name || "N/A"}</b>
                      </p>
                    </td>
                    <td>
                      {res?.serviceCategory?.service_category_name || "N/A"}
                    </td>

                    <td>{res?.serviceProvider?.full_name || "N/A"}</td>
                    <td>{res?.date || "N/A"}</td>
                    <td>
                      {res?.status == 1
                        ? "Pending"
                        : res?.status == 2
                        ? "Accepted"
                        : res?.status == 3
                        ? "Canceled"
                        : "Completed"}
                    </td>
                    {/* <td className="user-img">
                  <img src={require("../Assets/Images/user.png")} />
                </td> */}
                    {/* <td>
                      <div className="select-box">
                        <Form.Select
                          onChange={(e) => {
                            dispatch(
                              changeStatus({
                                userId: user?._id,
                                type: e.target.value,
                              })
                            ).then((res) => {
                              if (res?.payload?.success) {
                                toast.success(res?.payload?.message);
                                setflag(!flag);
                              } else {
                                toast.error(res?.payload?.message);
                              }
                            });
                          }}
                          value={user?.is_active}
                          aria-label="Default select example"
                        >
                          <option value="0">Active</option>
                          <option value="1">Confirmed</option>
                        </Form.Select>
                      </div>
                    </td> */}
                  </tr>
                );
              })}
            </tbody>
          </Table>
          {/* {data?.data?.users?.length == 0 && (
            <div className="no-data-updated">
              <p className="no-data">No data available yet</p>
            </div>
          )} */}
        </div>
      </Container>

      <Pagination totalstuff={pagi?.totalBookings / limit} setpages={setpage} />
    </Layout>
  );
}
