import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

export default function Orders({BookPercentage}) {

const data = {
  labels: [`Services Completed ${BookPercentage}%`],
  datasets: [
    {
      label: "# of Votes",
      data: [BookPercentage],
      backgroundColor: ["#038654"],

      borderWidth: 1,
    },
  ],
};


  return(
    <>
  <Doughnut className="gender-chart" data={data} />
  </>
  )
}
