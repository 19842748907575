import { createSlice } from "@reduxjs/toolkit";
// import { userListing } from "../Actions/AdminActions";
import { toast } from "react-toastify";
import { accountVerification, getCustomer, getCustomerProfile, getService, getServiceCategoryList, getUserProfile, userListing } from "../Actions/adminAction";

export const serviceCategorySlice = createSlice({
  name: "serviceCategorySlice",
  initialState: {
    List: "",
   
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getServiceCategoryList?.fulfilled, (state, action) => {
      if (action?.payload?.success) {
        state.List = action.payload;
      } else {
        toast.error(action?.payload?.message);
      }
    });
   
  },
});

export default serviceCategorySlice.reducer
