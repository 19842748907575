import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Button, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  DeleteCommunity,
  getCommunityList,
} from "../Redux/Actions/adminAction";
import Pagination from "../Components/Layout/Pagination";
import { toast } from "react-toastify";

export default function CommunityManagement() {
  const [showdel, setshowdel] = useState(false);
  const [page, setpage] = useState("1");
  const [search, setsearch] = useState("");
  const [limit, setlimit] = useState("10");
  const [flag, setflag] = useState(true);
  const [type, setType] = useState("");
  const [CommunityData, setCommunityData] = useState([]);
  const [pagi, setPagi] = useState("");
  const [ID, setID] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const getdata = async () => {
      const data = await dispatch(
        getCommunityList({ page, search, limit, type: type })
      );
      console.log(data);
      if (data?.payload?.status_code === 200) {
        setCommunityData(data?.payload?.data?.community);
        setPagi(data?.payload?.data);
      }
    };
    getdata();
  }, [page, search, limit, flag, type]);

  const handlersearch = (e) => {
    setsearch(e.target.value);
  };

  const DeleteCommunityHandle = async () => {
    const data = await dispatch(DeleteCommunity({ id: ID }));
    console.log(data);
    if (data?.payload?.status === 200) {
      toast.success(data?.payload?.data?.message);
      setflag(!flag);
      setshowdel(false);
    }
  };

  const handleuprofile = (user) => {
    navigate("/EventView", {
      state: {
        id: user,
      },
    });
  };

  const startIndex = 1 + (page - 1) * limit;
  const endIndex = Math.min(page * limit, pagi?.totalCommunities);

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>Community Management</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={4} md={4}>
                <Form.Group>
                  <Form.Control
                    type="search"
                    placeholder="Search.."
                    name="search"
                    value={search}
                    onChange={handlersearch}
                  />
                </Form.Group>
              </Col>

              <Col     xxl={9}
                xl={9}
                lg={8}
                md={8} className="d-flex justify-content-end align-items-center">
                <div className="cmn-btn">
                  <Link to="/CreateEvent">ADD eVENT</Link>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <hr />
        <Container fluid>
          <div className="product-tab">
            <ul>
              <li>
                <Link to="#" className="active-tab">
                  All
                </Link>
              </li>
              {/* <li>
                <Link to="#">Open  3,458</Link>
              </li>
              <li>
                <Link to="#">Closed  9,876</Link>
              </li> */}
            </ul>
          </div>
        </Container>
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">
                {" "}
                Showing {startIndex} - {endIndex} of {pagi?.totalCommunities}{" "}
                results
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select
                  aria-label="Default select example"
                  onChange={(e) => setlimit(e.target.value)}
                >
                  <option>10</option>
                  <option value="1">11</option>
                  <option value="2">12</option>
                  <option value="3">13</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Date</th>
                <th>Event Name</th>
                {/* <th>Last Name</th> */}
                <th>Address </th>
                <th>Description</th>
                <th>Action</th>
              </tr>
            </thead>

            <tbody>
              {CommunityData?.map((details, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>
                      <p className="m-0">
                        {`${new Date(details?.eventDate).toLocaleDateString(
                          "en-GB"
                        )}`}
                      </p>
                    </td>
                    <td>
                      <p
                        style={{ cursor: "pointer" }}
                        onClick={() => handleuprofile(details?._id)}
                      >
                        <b>{details?.eventName ? details?.eventName : "N/A"}</b>
                      </p>
                    </td>

                    <td>
                      {details?.eventAddress ? details?.eventAddress : "N/A"}
                    </td>
                    <td>
                      {details?.description ? details?.description : "N/A"}
                    </td>
                    <td>
                      <div className="">
                        <Button
                          className="account-btn theme-colr-btn"
                          onClick={() => {
                            setshowdel(true);
                            setID(details?._id);
                          }}
                        >
                          Remove
                        </Button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </Container>
      <Modal show={showdel} className="delete-popup">
        <Modal.Body>
          <h2 className="are-sure-text">
            Are you sure you want to remove this community ?
          </h2>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button className="theme-btn" onClick={DeleteCommunityHandle}>
            Yes
          </Button>
          <Button className="theme-btn" onClick={() => setshowdel(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
      <Pagination
        totalstuff={pagi?.totalCommunities / limit}
        setpages={setpage}
      />
    </Layout>
  );
}
