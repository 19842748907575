import { createSlice } from "@reduxjs/toolkit";
// import { userListing } from "../Actions/AdminActions";
import { toast } from "react-toastify";
import { accountVerification, getCustomer, getCustomerProfile, getService, getUserProfile, userListing } from "../Actions/adminAction";

export const userMgmtSlice = createSlice({
  name: "userMgmtSlice",
  initialState: {
    customerList: "",
    customerDetails:"",
    unverifiedUsers:"",
    serviceUsers:""
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCustomer?.fulfilled, (state, action) => {
      if (action?.payload?.success) {
        state.customerList = action.payload;
      } else {
        toast.error(action?.payload?.message);
      }
    });
    builder.addCase(getCustomerProfile?.fulfilled, (state, action) => {
      
      if (action?.payload?.success) {
        
        state.customerDetails = action.payload;
      } else {
        toast.error(action?.payload?.message);
      }
    });
    builder.addCase(accountVerification?.fulfilled, (state, action) => {
      
      if (action?.payload?.success) {
        
        state.unverifiedUsers = action.payload;
      } else {
        toast.error(action?.payload?.message);
      }
    });
    builder.addCase(getService?.fulfilled, (state, action) => {
      
      if (action?.payload?.success) {
        
        state.serviceUsers = action.payload;
      } else {
        toast.error(action?.payload?.message);
      }
    });
  },
});

export default userMgmtSlice.reducer
