import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Await, Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { resetPassword } from "../Redux/Actions/adminAction";

export default function ForgotPasswordtwo() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [forgetPass, setforgetPass] = useState({
    password: "",

    email: location?.state?.email,
  });
  console.log(location);
  const [confrmPass, setConfirmpass] = useState("");
  const [confrmPasserr, setConfirmpasserr] = useState("");
  const [forgetPasserr, setforgetPasserr] = useState("");

  const handlecahnge = (e) => {
    setforgetPass((old) => ({ ...old, [e.target.name]: e.target.value }));

    setforgetPasserr("");
  };

  const handleclick = async (e) => {
    let isvalid = true;

    if (!forgetPass.password) {
      setforgetPasserr("Required");
      isvalid = false;
    }
    if (!confrmPass) {
      setConfirmpasserr("Required");
      isvalid = false;
    }
    if (confrmPass !== forgetPass.password) {
      setConfirmpasserr("Password did not match");
      isvalid = false;
    }

    if (isvalid) {
      try {
        let data = await dispatch(
          resetPassword({
            newPassword: forgetPass?.password,
            confirmPassword: confrmPass,
            email: forgetPass?.email,
          })
        );
        console.log(data);
        if (data?.payload?.data?.success) {
          toast.success(data?.payload?.data?.message);
          navigate("/");
        } else {
          toast.error(data?.payload?.data?.message);
        }
      } catch (error) {
        console.log(error, "kjagsdfuiwqgeufjbewq;kjbeou");
      }
    }
  };

  return (
    <div>
      <Container fluid>
        <Row>
          <Col lg={12}>
            <div className="logo">
              <img src={require("../Assets/Images/logo-main.svg").default} />
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="box-center">
          <Col lg={12} className="m-auto">
            <div className="login-cmn-box">
              <div className="login-box-inner-wrap">
                <h2 className="text-center">Enter New Password</h2>
                <Form>
                  <div className="form-set">
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>Enter Password</Form.Label>
                      <Form.Control
                        type="password"
                        name="password"
                        value={forgetPass.password}
                        onChange={handlecahnge}
                        placeholder="Type Here"
                      />
                      <Form.Label className="text-danger">
                        {forgetPasserr}
                      </Form.Label>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>Confirm Password</Form.Label>
                      <Form.Control
                        type="password"
                        name="confrmPass"
                        value={confrmPass}
                        onChange={(e) => {
                          setConfirmpass(e.target.value);
                          setConfirmpasserr("");
                        }}
                        placeholder="Type Here"
                      />
                      <Form.Label className="text-danger">
                        {confrmPasserr}
                      </Form.Label>
                    </Form.Group>
                  </div>
                  <div
                    onClick={handleclick}
                    variant="primary"
                    type="button"
                    className="submit"
                  >
                    Log In
                  </div>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
