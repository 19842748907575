import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Button, Figure, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  DelecteAccount,
  changeStatus,
  getUserProfile,
} from "../Redux/Actions/adminAction";
import logo from "../Assets/Images/placeholder 1 .png";
import { toast } from "react-toastify";
import ShowingResult from "../Components/Layout/ShowingResult";
import Limit from "../Components/Layout/Limit";

export default function AllServices() {

  const [page, setpage] = useState("1");
  const [search, setsearch] = useState("");
  const [limit, setlimit] = useState("10");
  const [flag, setflag] = useState(true);
  const [pageType, setPageType] = useState("");

  const [show, setshow] = useState(false);
  const [showdel, setshowdel] = useState(false);
  const dispatch = useDispatch();
  const data = useSelector((state) => state.usermgmtData.userdetails);
  console.log(data, "userList");
  let BASE_URL = process.env.REACT_APP_FILE_BASE_URL;

  const { id } = useParams();

  useEffect(() => {
    dispatch(getUserProfile({ userId: id }));
  }, [id]);

  const handledelclick = async () => {
    setshowdel(true);
  };

  const handleclick = async () => {
    setshow(true);
  };

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <div>
            <h2>All Services</h2>
          </div>
        </div>
        <hr />
      </div>

      <Container fluid>
        <div className="product-cmn-tab mb-4 pe-4">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link to="/CustomerManagement">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>
            <Col
              lg={8}
              md={8}
              className="d-flex justify-content-end align-items-center p-0"
            >
              <div className="cmn-btn">
                <Link to="/ProvidersProfile" className="me-3">
                  Overview
                </Link>
                <Link to="/AllServices">All Services</Link>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">
                {" "}
                <ShowingResult page={page} limit={limit} />
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span> <Limit setlimit={setlimit} />
              </div>
            </Col>
          </Row>
        </div>
      </Container>
      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Service Category</th>
                <th>Service name</th>
                <th>Decscription</th>
                {/* <th>Profile Image</th> */}
                {/* <th>Position</th> */}
                {/* <th>Location</th>
                <th>Status</th> */}
                <th>Price</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>01</td>
                <td>
                  <Link to="/ServiceDetails">
                    <b>Dummyname</b>
                  </Link>
                </td>

                <td>Dummyname</td>
                <td>
                  Ipsum has been the industry's standard dummy text ever since
                  the 1500s
                </td>
                <td>10000</td>
                <td>
                  <div className="">
                    <Button className="account-btn theme-colr-btn">
                      Remove
                    </Button>
                  </div>
                </td>
              </tr>
            </tbody>
          </Table>
          {data?.data?.users?.length == 0 && (
            <div className="no-data-updated">
              <p className="no-data">No data available yet</p>
            </div>
          )}
        </div>
      </Container>
    </Layout>
  );
}
