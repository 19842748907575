import { createAsyncThunk } from "@reduxjs/toolkit";

import { toast } from "react-toastify";
import Adminapi from "../../services/AdminApi";



export const adminLogin = createAsyncThunk("Login", async (details) => {
    try {
        const { data } = await Adminapi.post("/adminLogin", details);
        console.log(data);
    
        if (data?.success) {
          toast.success(data?.message);
          sessionStorage.setItem("token", data?.data?.token);
          setTimeout(() => {
            toast.dismiss()
            window.location.href = "Dashboard";
          }, 2000);
        } else {
          toast.error(data?.message);
        }
    
        return data;
      } catch (error) {
        toast.error(error?.response?.data?.message);
       
      }


});
