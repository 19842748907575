import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { AddSOS } from "../Redux/Actions/adminAction";
import { toast } from "react-toastify";

const validationSchema = Yup.object({
  name: Yup.string()
    .trim()
    .required("Required")
    .min(3, "Name should be at least 3 characters long"),
    contact_number: Yup.string()
    .trim()
    .required("Required")
    .matches(/^[0-9]+$/, "Only digits are allowed")
    .min(10, "Number should be at least 10 digits")
    .max(15, "Number can't be longer than 15 digits"),
});

export default function AddSOSNumber() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <h2>Add SOS Number</h2>
          <div>
            <hr />
          </div>
        </div>
      </div>
      <Container fluid>
        <div className="product-cmn-tab">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link to="/SOSManagement">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
      <Container fluid>
        <Row className="justify-content-center ">
          <Formik
            initialValues={{ name: "", contact_number: "" }}
            validationSchema={validationSchema}
            onSubmit={async (values) => {
              console.log(values);
              try {
                const data = await dispatch(AddSOS(values));
                console.log(data);
                if (data?.payload?.data?.success) {
                  toast.success(data?.payload?.data?.message);
                  navigate("/SOSManagement");
                } else {
                  toast.error(data?.payload?.data?.message);
                }
              } catch (error) {
                console.log(error, "errorrrr");
              }
            }}
          >
            {({ values, handleChange, handleSubmit }) => (
              <Col lg={6}>
                <Form onSubmit={handleSubmit}>
                  <div className="customer-form-new">
                    <div className="customer-form-inner">
                      <Row>
                        <Col lg={12}>
                          <Form.Group className="mb-3">
                            <Form.Label>Contact Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Text here..."
                              name="name"
                              value={values?.name}
                              onChange={handleChange}
                            />
                            <ErrorMessage name="name" component="div" className="error" />
                          </Form.Group>
                        </Col>
                        <Col lg={12}>
                          <Form.Group className="mb-3">
                            <Form.Label>Number</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Text here..."
                              name="contact_number"
                              value={values?.contact_number}
                              onChange={handleChange}
                            />
                            <ErrorMessage
                              name="contact_number" className="error"
                              component="div"
                            />
                          </Form.Group>
                        </Col>

                        <Col
                          lg={12}
                          className="d-flex justify-content-center mt-3"
                        >
                          <button type="submit" className="add-btn">
                            create
                          </button>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Form>
              </Col>
            )}
          </Formik>
        </Row>
      </Container>
    </Layout>
  );
}
