import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Button, Figure, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  DelecteAccount,
  changeStatus,
  getUserProfile,
} from "../Redux/Actions/adminAction";
import logo from "../Assets/Images/placeholder 1 .png";
import { toast } from "react-toastify";

export default function AccountProfile() {
  const [show, setshow] = useState(false);
  const [showdel, setshowdel] = useState(false);
  const dispatch = useDispatch();
  const data = useSelector((state) => state.usermgmtData.userdetails);
  console.log(data, "userList");
  let BASE_URL = process.env.REACT_APP_FILE_BASE_URL;

  const { id } = useParams();

  useEffect(() => {
    dispatch(getUserProfile({ userId: id }));
  }, [id]);

  const handledelclick = async () => {
    setshowdel(true);
  };

  const handleclick = async () => {
    setshow(true);
  };

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <div>
            <h2>User Profile</h2>
          </div>
        </div>
        <hr />
      </div>

      <Container fluid>
        <div className="product-cmn-tab mb-4 pe-4">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link to="/AccountVerification">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>
            <Col
              lg={8}
              md={8}
              className="d-flex justify-content-end align-items-center p-0"
            ></Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="height-set px-4">
        <Row>
          <Col lg={12}>
            <Row>
              <Col lg={8}>
                <div className="user-profile-main mb-4">
                  <Row>
                    <Col lg={3} xl={3} xxl={2}>
                      <Figure className="d-flex">
                        <Figure.Image
                          width={171}
                          height={180}
                          alt="171x180"
                          src={
                            data?.data?.profile_image
                              ? `${BASE_URL}${data?.data?.profile_image}`
                              : logo
                          }
                        />
                        <Figure.Caption>
                          {/* <h2>{data?.data?.name || "N/A"}</h2>
                          <p>{data?.data?.email || "N/A"}</p>
                          <p>{data?.data?.phone_number || "N/A"}</p> */}
                        </Figure.Caption>
                      </Figure>
                    </Col>
                    <Col lg={9} xl={9} xxl={10}>
                      <div className=" px-3 py-4 h-100">
                        <div className="property-main mt-0">
                          <h2 className="property">Profile info</h2>
                          <div className="customer-form-new border-none">
                            <Row>
                              <Col lg={6}>
                                <Form.Group className="mb-3">
                                  <Form.Label>Owner name</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="dummy"
                                  />
                                </Form.Group>
                              </Col>
                              <Col lg={6}>
                                <Form.Group className="mb-3">
                                  <Form.Label>Company name</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="dummy"
                                  />
                                </Form.Group>
                              </Col>
                              <Col lg={6}>
                                <Form.Group className="mb-3">
                                  <Form.Label>Phone number</Form.Label>
                                  <Form.Control
                                    type="number"
                                    placeholder="dummy"
                                  />
                                </Form.Group>
                              </Col>

                              <Col lg={6}>
                                <Form.Group className="mb-3">
                                  <Form.Label>Email</Form.Label>
                                  <Form.Control
                                    type="email"
                                    placeholder="dummy"
                                  />
                                </Form.Group>
                              </Col>
                              <Col lg={6}>
                                <Form.Group className="mb-3">
                                  <Form.Label>Address</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="dummy"
                                  />
                                </Form.Group>
                              </Col>
                              <Col lg={6}>
                                <Form.Group className="mb-3">
                                  <Form.Label>Landmark</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="dummy"
                                  />
                                </Form.Group>
                              </Col>
                              <Col
                                lg={12}
                                className="d-flex justify-content-end mt-3"
                              >
                                <button type="button" className="add-btn">
                                  Save
                                </button>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="user-profile-main mb-4">
                  <Row>
                    <Col lg={3} xl={3} xxl={2}>
                      <Figure className="d-flex">
                        <Figure.Image
                          width={171}
                          height={180}
                          alt="171x180"
                          src={
                            data?.data?.profile_image
                              ? `${BASE_URL}${data?.data?.profile_image}`
                              : logo
                          }
                        />
                        <Figure.Caption>
                          {/* <h2>{data?.data?.name || "N/A"}</h2>
                          <p>{data?.data?.email || "N/A"}</p>
                          <p>{data?.data?.phone_number || "N/A"}</p> */}
                        </Figure.Caption>
                      </Figure>
                    </Col>
                    <Col lg={9} xl={9} xxl={10}>
                      <div className=" px-3 py-4 h-100">
                        <div className="property-main mt-0">
                          <h2 className="property">
                            Reference employee details{" "}
                          </h2>
                          <div className="customer-form-new border-none">
                            <Row>
                              <Col lg={6}>
                                <Form.Group className="mb-3">
                                  <Form.Label>Employee name</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="dummy"
                                  />
                                </Form.Group>
                              </Col>
                              <Col lg={6}>
                                <Form.Group className="mb-3">
                                  <Form.Label>Phone number </Form.Label>
                                  <Form.Control
                                    type="number"
                                    placeholder="dummy"
                                  />
                                </Form.Group>
                              </Col>
                              <Col lg={6}>
                                <Form.Group className="mb-3">
                                  <Form.Label>Email </Form.Label>
                                  <Form.Control
                                    type="email"
                                    placeholder="dummy"
                                  />
                                </Form.Group>
                              </Col>
                              <Col lg={6}>
                                <Form.Group className="mb-3">
                                  <Form.Label>Relation with compnay</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="dummy"
                                  />
                                </Form.Group>
                              </Col>
                              <Col lg={6}>
                                <Form.Group className="mb-3">
                                  <Form.Label>Employee designation </Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="dummy"
                                  />
                                </Form.Group>
                              </Col>
                              <Col
                                lg={12}
                                className="d-flex justify-content-end mt-3"
                              >
                                <button type="button" className="add-btn">
                                  Save
                                </button>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                <Row className="mb-4 ">
                  <Col lg={12}>
                    <div className="user-profile-main px-3 py-4 h-100">
                      <div className="property-main mt-0">
                        <h2 className="property mb-2">Documents</h2>
                        <div className="images-gallery">
                          <Row>
                            <Col lg={3}>
                              <div className="image-box-item">
                                <p>Government ID</p>
                                <img
                                  src={require("../Assets/Images/profile-img.png")}
                                />
                              </div>
                            </Col>
                            <Col lg={3}>
                              <div className="image-box-item">
                                <p>Business licence</p>
                                <img
                                  src={require("../Assets/Images/profile-img.png")}
                                />
                              </div>
                            </Col>
                            <Col lg={3}>
                              <div className="image-box-item">
                                <p>Permit</p>
                                <img
                                  src={require("../Assets/Images/profile-img.png")}
                                />
                              </div>
                            </Col>
                            <Col lg={3}>
                              <div className="image-box-item">
                                <p>Certifications</p>
                                <img
                                  src={require("../Assets/Images/profile-img.png")}
                                />
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col lg={4}>
                <Row>
                  <Col lg={12} className="mb-4">
                    <div className="user-profile-main px-3 py-4  h-100">
                      <div className="property-main mt-0">
                        <h2 className="property">Profile Action</h2>
                        <div className="mt-2">
                          <div className="cmn-btn mt-4 mb-4">
                            <Link to="#" className="me-3">
                              Accept
                            </Link>
                            <Link to="/AllServices">rEJECT</Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} className="">
                    <div className="user-profile-main px-3 py-4 h-100">
                      <div className="property-main mt-0">
                        <h2 className="property">SOCIAL MEDIA</h2>
                        <div className="active-default-links">
                          <ul>
                            <li>
                              <h6>Facebook</h6>
                              <p>Dummy</p>
                            </li>
                            <li>
                              <h6>Instagram</h6>
                              <p>Dummy</p>
                            </li>
                            <li>
                              <h6>TikTok</h6>
                              <p>Dummy</p>
                            </li>
                            <li>
                              <h6>Youtube</h6>
                              <p>Dummy</p>
                            </li>
                            <li>
                              <h6>Twitter</h6>
                              <p>Dummy</p>
                            </li>
                            <li>
                              <h6>Website</h6>
                              <p>Dummy</p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} className="mt-4">
                    <div className="user-profile-main px-3 py-4 h-100">
                      <div className="property-main mt-0">
                        <h2 className="property">Activity</h2>
                        <div className="active-default-links">
                          <ul>
                            <li>
                              <h6>[Admin Name] lorem ipsum is dummy text.</h6>
                              <p>01/01/2023 13:32</p>
                            </li>
                            <li>
                              <h6>[Admin Name] lorem ipsum is dummy text.</h6>
                              <p>01/01/2023 13:32</p>
                            </li>
                            <li>
                              <h6>[Admin Name] lorem ipsum is dummy text.</h6>
                              <p>01/01/2023 13:32</p>
                            </li>
                            <li>
                              <h6>[Admin Name] lorem ipsum is dummy text.</h6>
                              <p>01/01/2023 13:32</p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}
