import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { addTutorial } from "../Redux/Actions/adminAction";

const validationSchema = Yup.object().shape({
  title: Yup.string()
     .trim()
    .required("Required")
    .min(5, "Title must be at least 5 characters")
    .max(100, "Title must not exceed 100 characters"),

    description: Yup.string()
    .trim()
    .required("Required")
    .min(10, "Description must be at least 10 characters")
    .max(500, "Description must not exceed 500 characters"),

    video: Yup.mixed()
    .required("Required")
    .test("fileType", "Unsupported File Format", (value) => {
      return value && ["video/mp4", "video/webm", "video/ogg"].includes(value.type);
    })
    // .test("fileSize", "File too large", (value) => {
    //   return value && value.size <= 10485760; 
    // }),
});


export default function AddTutorial() {

  const [videoPreview, setVideoPreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const Initialvalues = {
    title:"",
    description:"",
    video:null
  }

  const handleTutorialSubmit = async(values) => {

    const formData = new FormData();
      formData.append('title', values.title);
      formData.append('description', values.description);
      formData.append('video', values.video);

    try{
      setLoading(true)
     const data = await dispatch(addTutorial(formData));
     console.log(data);
     if (data?.payload?.data?.success) {
      toast.success(data?.payload?.data?.message);
      navigate("/TrainingMaterial");
    } else {
      toast.error(data?.payload?.data?.message);
    }
    }catch(error){
     console.log(error,"error occur");
     setLoading(false);
    }
  }

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <h2>Add Tutorial</h2>
          <div>
            <hr />
          </div>
        </div>
      </div>
      <Container fluid>
        <div className="product-cmn-tab">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link to="/TrainingMaterial">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
      <Container fluid>
        <Row className="justify-content-center ">
          <Formik initialValues={Initialvalues}
                validationSchema={validationSchema}
                onSubmit={handleTutorialSubmit}
          >
          {({ values, handleChange, handleSubmit,setFieldValue }) => (        
          <Col lg={6}>
            <Form onSubmit={handleSubmit}>
            <div className="customer-form-new">
              <div className="customer-form-inner">
                <Row>
                  <Col lg={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Tutorial Title</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Add Title..."
                        name="title"
                        value={values?.title}
                        onChange={handleChange}
                      />
                       <ErrorMessage name="title" component="div" className="error" />
                    </Form.Group>
                  </Col>
                  <Col lg={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Add Title..."
                        name="description"
                        value={values?.description}
                        onChange={handleChange}
                      />
                       <ErrorMessage name="description" component="div" className="error" />
                    </Form.Group>
                  </Col>
                  <Col lg={12}>
                          <Form.Group className="mb-3">
                            <Form.Label>Add Video</Form.Label>
                            <div className="add-photo-h">
                              <Form.Control
                                type="file"
                                name="video"
                                accept="video/*"
                                onChange={(event) => {
                                  const file = event.currentTarget.files[0];
                                  console.log(file);
                                  setFieldValue("video", file);
                                  
                                  if (file) {
                                    const videoUrl = URL.createObjectURL(file);
                                    setVideoPreview(videoUrl);
                                  }
                                }}
                              />
                              <span>Choose Video</span>
                               {videoPreview && (
                            <div className="video-preview">
                              <video
                                width="100%"
                                height="140px"
                                controls
                                src={videoPreview}
                              ></video>
                            </div>
                        )}
                            </div>
                            <ErrorMessage name="video" component="div" className="error" />
                          </Form.Group>
                        </Col>
                       


                  <Col lg={12} className="d-flex justify-content-center mt-3">
                    <button type="submit" className="add-btn" disabled={loading}>
                    {loading ? 'Loading...' : 'Create'}
                    </button>
                  </Col>
                </Row>
              </div>
            </div>
            </Form>
          </Col>
          )}
          </Formik>
        </Row>
      </Container>
    </Layout>
  );
}
