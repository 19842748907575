import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { Modal,Button } from "react-bootstrap";
import {
  changeStatus,
  downloadCustomer,
  getCustomer,
} from "../Redux/Actions/adminAction";
import Search from "../Components/Layout/Search";
import ShowingResult from "../Components/Layout/ShowingResult";
import Limit from "../Components/Layout/Limit";
import { toast } from "react-toastify";
import Pagination from "../Components/Layout/Pagination";
import * as XLSX from "xlsx";
import logo from "../Assets/Images/placeholder 1 .png";

export default function CustomerManagement() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data = useSelector((state) => state.usermgmtData.customerList);

  const [page, setpage] = useState("1");
  const [search, setsearch] = useState("");
  const [limit, setlimit] = useState("10");
  const [flag, setflag] = useState(true);
  const [type, setType] = useState("");
  const [show, setshow] = useState(false);
  const [ID, setID] = useState("");
  const [value, setvalue] = useState("");

  useEffect(() => {
    dispatch(getCustomer({ page, search, limit, type: type }));
  }, [page, search, limit, flag, type]);

  const fetchDataAndExportToExcel = useCallback(async () => {
    try {
      const response = await dispatch(downloadCustomer());
      console.log(response);

      const allData = response?.payload?.data?.user;

      const ws = XLSX.utils.json_to_sheet(allData);

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

      XLSX.writeFile(wb, "exported_data.xlsx", {
        bookType: "xlsx",
        bookSST: false,
        type: "blob",
      });
    } catch (error) {
      console.error("Error fetching or exporting data:", error);
    }
  }, [dispatch]);

  const handleprofileAction = () => {
    dispatch(changeStatus({status:value,id:ID})).then((res) => {
      if (res?.payload?.success) {
        toast.success(res?.payload?.message);
        setflag(!flag);
        setshow(false);
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>Customer Management</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={4} md={4}>
                <Form.Group>
                  <Search setsearch={setsearch} />
                </Form.Group>
              </Col>
              <Col xxl={2} xl={3} lg={4} md={4}>
                <Form.Select
                  onChange={(e) => setType(e.target.value)}
                  aria-label="Default select example"
                >
                  <option value="">All</option>
                  <option value="1">Active</option>
                  <option value="0">Temporary Banned</option>
                </Form.Select>
              </Col>
              <Col
                xxl={7}
                xl={6}
                lg={4}
                md={4}
                className="d-flex justify-content-end align-items-center"
              >
                <div className="cmn-btn">
                  <Link onClick={fetchDataAndExportToExcel}>DOWNLOAD</Link>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <hr />
        <Container fluid>
          <div className="product-tab">
            <ul>
              <li>
                <Link to="/CustomerManagement" className="active-tab">
                  All
                </Link>
              </li>
              
            </ul>
          </div>
        </Container>
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">
                {" "}
                Showing {1 + (page - 1) * limit} -{" "}
                {data?.data?.user?.length + (page - 1) * limit} of{" "}
                {data?.data?.totalUser} results
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select
                  onChange={(e) => setlimit(e.target.value)}
                  aria-label="Default select example"
                >
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Profile Name </th>
                <th>Phone Number </th>

                <th>Profile Image</th>
                <th>Email</th>
                {/* <th>Position</th> */}
                <th>Address</th>
                <th>Landmark</th>
                <th>Status</th>
                <th>Profile Action</th>
              </tr>
            </thead>
            <tbody>
              {data?.data?.user?.map((user, index) => {
                const serialNumber = (page - 1) * limit + index + 1;
                return (
                  <tr>
                    <td>{serialNumber}</td>
                    <td>
                      <Link to={`/CustomerProfile/${user?._id}`}>
                        <b> {user?.full_name || "N/A"}</b>
                      </Link>
                    </td>

                    <td>
                      {user?.country_code} {user?.phone_number || "N/A"}
                    </td>

                    <td className="user-img">
                      <img
                        src={
                          user?.profile_image
                            ? `https://simba-api.bosselt.com/public${user?.profile_image}`
                            : logo
                        }
                      />
                    </td>
                    <td>{user?.email || "N/A"}</td>
                    <td>{user?.address || "N/A"}</td>
                    <td>{user?.landMark || "N/A"}</td>
                    <td>{user?.status === 0 ? "Banned" : "Active"}</td>
                    <td>
                      <div className="select-box">
                        <Form.Select
                          onChange={(e) => {setshow(true); setID(user?._id); setvalue(e.target.value)}}
                          value={user?.status}
                          aria-label="Default select example"
                        >
                          <option value="0">Temporary Banned</option>
                          <option value="1">Active</option>
                        </Form.Select>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          {data?.data?.user?.length == 0 && (
            <div className="no-data-updated">
              <p className="no-data">No data available yet</p>
            </div>
          )}
        </div>
      </Container>

      <Modal show={show} className="delete-popup">
        <Modal.Body>
          <h2 className="are-sure-text">
            Are you sure you want to change the profile status ?
          </h2>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button className="theme-btn" onClick={handleprofileAction}>
            Yes
          </Button>
          <Button className="theme-btn" onClick={() => setshow(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>

      <Pagination
        totalstuff={data?.data?.totalPage}
        limit={data?.data?.limit}
        setpages={setpage}
        search={search}
      />
    </Layout>
  );
}
