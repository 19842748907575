

import {configureStore} from "@reduxjs/toolkit"
import {userMgmtSlice} from "./Reducers/userMgmtSlice"
import { serviceCategorySlice } from "./Reducers/serviceCategory"


export const store=configureStore({
    reducer: {
        usermgmtData: userMgmtSlice.reducer,
        serviceCatData:serviceCategorySlice.reducer
        // dashboardData:dashboardSlice.reducer,
        // varificationData: verificationSlice.reducer,
        // cityData: citySlice.reducer,
        // categoryData: categorySlice.reducer,
        // productData: productSlice.reducer
      },
})