import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteSos,
  changeStatus,
  getAllSOS,
  userListing,
  userListingDownload,
} from "../Redux/Actions/adminAction";
import Search from "../Components/Layout/Search";
import ShowingResult from "../Components/Layout/ShowingResult";
import Limit from "../Components/Layout/Limit";
import { toast } from "react-toastify";
import Pagination from "../Components/Layout/Pagination";
import * as XLSX from "xlsx";
import { Button, Modal } from "react-bootstrap";

export default function SOSManagement() {
  const dispatch = useDispatch();
  const [page, setpage] = useState("1");
  const [search, setsearch] = useState("");
  const [limit, setlimit] = useState("10");
  const [flag, setflag] = useState(true);
  const [pageType, setPageType] = useState("");
  const [pagi, setPagi] = useState("");
  const [SosData, setSosList] = useState([]);
  const [showdel, setshowdel] = useState(false);
  const [ID, setId] = useState("");




  useEffect(() => {
    dispatch(getAllSOS({ page, search, limit }))
      .then((res) => {
        console.log(res);
        if (res?.payload?.success === true) {
          setSosList(res?.payload?.data);
          setPagi(res?.payload?.pagination);
        }
      })
      .catch((err) => {
        console.log(err, "Error Occur");
      });
  }, [page, search, limit, flag]);



  const DeleteSosHandle = async () => {
    const data = await dispatch(DeleteSos({ sos_id: ID }));
    console.log(data);
    if (data?.payload?.status === 200) {
      toast.success(data?.payload?.data?.message);
      setflag(!flag);
      setshowdel(false);
    }
  };

  const startIndex = 1 + (page - 1) * limit;
  const endIndex = Math.min(page * limit, pagi?.totalItems);
 

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>SOS Management</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={4} md={4}>
            
              </Col>
              {/* <Col xxl={3} xl={3} lg={4} md={4}>
              
              </Col> */}
              <Col
                  xxl={9}
                  xl={9}
                  lg={8}
                  md={8}
                className="d-flex justify-content-end align-items-center"
              >
                <div className="cmn-btn">
                  <Link to="/AddSOSNumber">Add Number’s</Link>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <hr />
        <Container fluid>
          <div className="product-tab">
            <ul>
              <li>
                <Link to="/CustomerManagement" className="active-tab">
                  All
                </Link>
              </li>
              {/* <li>
                <Link to="">Verified</Link>
              </li>
              <li>
                <Link to="">Rejected</Link>
              </li> */}
            </ul>
          </div>
        </Container>
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
          <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">
                {" "}
                Showing {startIndex} - {endIndex} of {pagi?.totalItems}{" "}
                results
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>
                <Form.Select
                  aria-label="Default select example"
                  name="limit"
                  onChange={(e) => setlimit(e.target.value)}
                >
                  <option>10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>S.No</th>
                <th>SOS Number</th>
                <th>Contact Name</th>
                <th>Profile Action</th>
              </tr>
            </thead>
            <tbody>
              {SosData?.map((res, index) => {
                const serialNumber = (page - 1) * limit + index + 1;
                return (
                  <tr>
                    <td>{serialNumber}</td>
                    <td>
                    {res?.contact_number || "N/A"}
                    </td>
                    <td >
                    {res?.name || "N/A"}
                </td>
                    <td>
                    <div className="">
                    <Button className="account-btn theme-colr-btn" onClick={() => {setshowdel(true); setId(res?._id)}}>
                      Remove
                    </Button>
                  </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          {/* {data?.data?.users?.length == 0 && (
            <div className="no-data-updated">
              <p className="no-data">No data available yet</p>
            </div>
          )} */}
        </div>
      </Container>

      <Modal show={showdel} className="delete-popup">
        <Modal.Body>
          <h2 className="are-sure-text">
            Are you sure you want to Remove this SOS
          </h2>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button className="theme-btn" onClick={DeleteSosHandle}>
            Yes
          </Button>
          <Button className="theme-btn" onClick={() => setshowdel(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>

      <Pagination
        totalstuff={pagi.totalItems / limit}
        setpages={setpage}
      />
    </Layout>
  );
}
