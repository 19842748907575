import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

export default function Users({Active,ActiveServicePro}) {

const data = {

  labels: [`Active Customers  ${Active}%`, `Active Service providers   ${ActiveServicePro}%`],
  datasets: [
    {
      label: "# of Votes",
      data: [Active, ActiveServicePro],
      backgroundColor: ["#038654", "#EBEFEF"],

      borderWidth: 1,
    },
  ],
};


  return (
    <>
  <Doughnut className="gender-chart" data={data} />
  </>
  )
}
