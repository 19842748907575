import React, { memo } from "react";
import Form from "react-bootstrap/Form";

 function Limit({setlimit}) {

  const handlechange=(e)=>{
    setlimit(e.target.value)

  }

  return (
    <div>
    
     
    </div>
  );
}
export default memo(Limit)
