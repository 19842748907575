import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Button, Figure, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import logo from "../Assets/Images/placeholder 1 .png";
import { toast } from "react-toastify";
import { getServiceProviderDetails } from "../Redux/Actions/adminAction";

export default function ServiceDetails() {
  const url = process.env.REACT_APP_FILE_BASE_URL;

  const [serviceDetails, setdetails] = useState({});
  const [show, setshow] = useState(false);
  const [showd, setshowd] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const getuserdata = async () => {
      const data = await dispatch(getServiceProviderDetails({ id: id }));
      console.log(data);
      if (data?.payload?.data?.success === true) {
        setdetails(data?.payload?.data?.data);
      }
    };
    getuserdata();
  }, [id]);

  const timeSlots = [
    "12am-01am",
    "10am-11am",
    "11am-12pm",
    "08am-09am",
    "04am-05am",
    "03am-04am",
    "02am-03am",
    "03pm-04pm",
    "05pm-06pm",
  ];

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <div>
            <h2>Service Provider Profile</h2>
          </div>
        </div>
        <hr />
      </div>

      <Container fluid>
        <div className="product-cmn-tab mb-4 pe-4">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link to="/ServiceProviders">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>
                  Back to previous page
                </Link>
              </div>
            </Col>
            <Col
              lg={8}
              md={8}
              className="d-flex justify-content-end align-items-center p-0"
            >
              {/* <div className="cmn-btn">
                <Link to="/ProvidersProfile" className="me-3">
                  Overview
                </Link>
                <Link to="/AllServices">Service details </Link>
              </div> */}
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="height-set px-4">
        <Row>
          <Col lg={12}>
            <Row>
              <Col lg={8}>
                <div className="user-profile-main mb-4">
                  <Row>
                    <Col lg={12} xl={12} xxl={12}>
                      <Figure className="d-flex">
                        <span>
                          <img
                            width={171}
                            height={180}
                            alt="171x180"
                            src={
                              serviceDetails?.serviceProviderId?.profile_image
                                ? `https://simba-api.bosselt.com/public${serviceDetails?.serviceProviderId?.profile_image}`
                                : logo
                            }
                          />
                        </span>
                        <Figure.Caption>
                          <h2>
                            {serviceDetails?.serviceProviderId?.full_name ||
                              "N/A"}
                          </h2>
                          <p>
                            {serviceDetails?.serviceProviderId?.email || "N/A"}
                          </p>
                          <p>
                            {serviceDetails?.serviceProviderId?.phone_number
                              ? serviceDetails?.serviceProviderId.country_code
                                ? `${serviceDetails?.serviceProviderId.country_code} ${serviceDetails?.serviceProviderId.phone_number}`
                                : `${serviceDetails?.serviceProviderId.phone_number}`
                              : "N/A"}
                          </p>
                        </Figure.Caption>
                      </Figure>
                    </Col>
                  </Row>
                </div>
                <Row>
                  <Col lg={12}>
                    <div className="user-profile-main px-3 py-4 h-100">
                      <div className="property-main mt-0">
                        <h2 className="property">Service info</h2>
                        <div className="customer-form-new border-none">
                          <Row>
                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Service Category</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Service Category"
                                  value={
                                    serviceDetails?.serviceCategoryId
                                      ?.service_category_name || "N/A"
                                  }
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Service name</Form.Label>
                                <Form.Control
                                  type="email"
                                  placeholder="Service Name"
                                  value={
                                    serviceDetails?.serviceSubCategoryName ||
                                    "N/A"
                                  }
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Description</Form.Label>
                                <Form.Control
                                  as="textarea"
                                  rows={3}
                                  placeholder="Description"
                                  value={serviceDetails?.desc || "N/A"}
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Price</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Price"
                                  value={serviceDetails?.price || "N/A"}
                                />
                              </Form.Group>
                            </Col>
                            {serviceDetails?.availability?.map((res,index) => {
                              return (
                                <Col lg={12} key={index} className="">
                                  <Form.Group className="mb-3">
                                    <Form.Label>Time slots</Form.Label>
                                  </Form.Group>
                                  <div className="profile-time-set">
                                    <div className="time-set-list mb-3">
                                      <p>
                                        {res?.day === 0
                                          ? "Sunday"
                                          : res?.day === 1
                                          ? "Thursday"
                                          : res?.day === 2
                                          ? "Monday"
                                          : res?.day === 3
                                          ? "Thursday"
                                          : res?.day === 4
                                          ? "Friday"
                                          : res?.day === 5
                                          ? "Saturday"
                                          : "Wednesday"}
                                      </p>
                                      <Form.Group className="">
                                        <Form.Control
                                          type="text"
                                          placeholder="dummy"
                                          value={
                                            res?.timeArr === 0
                                              ? "12am-01am"
                                              : res?.timeArr === 1
                                              ? "10am-11am"
                                              : res?.timeArr === 2
                                              ? "11am-12pm"
                                              : res?.timeArr === 3
                                              ? "08am-09am"
                                              : res?.timeArr === 4
                                              ? "04am-05am"
                                              : res?.timeArr === 5
                                              ? "03am-04am"
                                              : res?.timeArr === 6
                                              ? "02am-03am"
                                              : res?.timeArr === 7
                                              ? "03pm-04pm"
                                              : "05pm-06pm"
                                          }
                                        />
                                      </Form.Group>
                                    </div>
                                  </div>
                                </Col>
                              );
                            })}
                            <Col
                              lg={12}
                              className="d-flex justify-content-end mt-3"
                            ></Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col lg={4}>
                <Row>
                  <Col lg={12}>
                    <div className="user-profile-main px-3 py-4 h-100">
                      <div className="property-main mt-0">
                        <h2 className="property mb-2">Photos</h2>
                        <div className="images-gallery">
                          <Row>
                            {serviceDetails?.images?.map((res) => {
                              return (
                                <Col lg={6}>
                                  <div className="image-box-item">
                                    <img
                                      src={
                                        res
                                          ? `https://simba-api.bosselt.com/public/user/${res}`
                                          : "Images Not Found"
                                      }
                                    />
                                  </div>
                                </Col>
                              );
                            })}
                          </Row>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}
